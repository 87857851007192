import { request } from '@/utils/axios'

export function deleteCollectCourceById(ids) {
  return request({
    url: `/api/v1/personalcenter/userCourseCollectionRecords/${ids}`,
    method: 'delete',
    verifyToken: true
  })
}
export function deleteCollectSpecialById(ids) {
  return request({
    url: `/api/v1/personalcenter/userCollectionTopicsRecords/${ids}`,
    method: 'delete',
    verifyToken: true
  })
}
