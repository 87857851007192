import { request } from '@/utils/axios'

export function collectSpecialData(record, that) {
  return new Promise((resolve, reject) => {
    // 如果用户狂点，操作顺序为[未收藏,收藏,未收藏]，此时最后一次和第一次状态一样，那么不发送更变的请求。
    // 这里用布尔值来判断是否是偶数次
    record._isSend = !record._isSend
    // 新增防抖，避免用户狂点
    if (that.checkTimer) {
      clearTimeout(that.checkTimer)
    }
    that.checkTimer = setTimeout(() => {
      // 状态和第一次不一样时，才需要请求
      if (record._isSend) {
        postCollectSpecial(record, that).then(res => {
          resolve(res)
        }).catch(res => {
          reject(res)
        }).finally(() => {
          delete record._isSend
        })
      }
    }, 300)
  })
}

/**
 * 收藏专题
 * @param specialId
 * @param that
 * @returns {Promise<unknown>} res.result: CourseModel
 */
export function postCollectSpecial(record, that) {
  return request({
    url: `/api/v1/personalcenter/userCollectionTopicsRecords`,
    method: 'post',
    data: {
      // 传给后台还需要取一次反
      actionStatus: record.collectType === '0' ? '1' : '0',
      collectionTopicsId: record.id
    },
    verifyToken: true
  })
}
