<template>
  <account type="wdsc" tabText="我的收藏">
    <div style="height: calc(100% - 48px);">
      <ep-space style="background: #fff;" align="center">
        <el-menu
          mode="horizontal"
          style="flex: 1"
          :default-active="currentType"
          @select="handleSelect">
          <!--          <el-menu-item index="all">-->
          <!--            <i class="el-icon-menu"></i>-->
          <!--            <span slot="title">全部</span>-->
          <!--          </el-menu-item>-->
          <el-menu-item index="course">
            <i class="el-icon-star-off"></i>
            <span slot="title">收藏课程</span>
          </el-menu-item>
          <el-menu-item index="special">
            <i class="el-icon-document"></i>
            <span slot="title">收藏专题</span>
          </el-menu-item>
        </el-menu>
        <div class="flex-center" v-if="!isCheck" @click="isBatchOperation">
          <span class="span">批量操作</span>
        </div>
        <div v-else class="flex-center" style="height:60px;">
          <span class="back-to-info" @click="isBatchOperation"><i class="el-icon-arrow-left"></i>返回</span>
          <a style="color:#578CEF;margin-right: 10px" @click="onClearSelected">清空选择</a>
          <div>已选 <span style="color:red;">{{ checkList.length }}</span> 项</div>
          <el-popconfirm
            title="确定删除吗？"
            @confirm="handleDelete"
          >
            <el-button type="danger" style="width: 100px;margin:0 10px;height: 40px" slot="reference">
              删除收藏
            </el-button>
          </el-popconfirm>
        </div>
      </ep-space>

      <el-skeleton :rows="10" animated v-show="isLoading"/>
      <div style="border-top: solid 1px #e6e6e6;" v-show="!isLoading">
        <div v-if="dataSource.length === 0" class="notdata">
          <el-empty description="暂时没有数据"></el-empty>
        </div>
        <template v-else>

          <table class="course_table table">
            <tbody>
            <template v-if="!isCheck">
              <template v-for="item in dataSource">
                <!--                跳转专题合集-->
                <account-course-item
                  v-if="item.profile"
                  :item="item"
                  :key="item.id"
                  @specialDet="loadData"
                  @click.native="item.isEffective === hasEffective.VALID?routerToSpecialDetail(item):alertSpecialMsg(item)"/>
                <!--                跳转课程详情-->
                <account-course-item
                  v-else
                  :item="item"
                  :key="item.id"
                  @courseDet="loadData"
                  @click.native="item.isEffective === hasEffective.VALID?routerToCaseDetail(item):alertCasesMsg(item)"/>
              </template>
            </template>

            <el-checkbox-group v-model="checkList" v-else>
              <template v-for="item in dataSource" @click="handleDelete">
                <el-checkbox
                  class="course-row"
                  :label="item.id"
                  :key="item.id">
                  <account-course-item :item="item"/>
                </el-checkbox>
              </template>
            </el-checkbox-group>
            </tbody>
          </table>

          <!--分页区域-->
          <p-pagination-base
            :currentPage.sync="currentPage"
            :pageSize.sync="pageSize"
            :total="total"
            @currentPageChange="handleCurrentChange"/>
        </template>
      </div>
      <el-backtop :bottom="100" :visibility-height="50"></el-backtop>
    </div>

  </account>
</template>

<script>
  import Account from '@views/account/Account'
  import { getAccountCourse, getAccountCollectCourse } from '@/api/account'
  import PImage from '@/components/project/image/PImage'
  import AccountCourseItem from '@/views/account/components/AccountCourseItem'
  import { deleteCollectCourceById, deleteCollectSpecialById } from '@api/collect'
  import { getEffectiveTypeStatus, hasEffective } from '@/enum/effectiveType'
  import PPaginationBase from '@/components/project/pagination/PPaginationBase'

  export default {
    name: 'AccountCollect',
    components: { PPaginationBase, AccountCourseItem, PImage, Account },
    data() {
      return {
        isCheck: false,
        currentType: 'course',
        currentPage: 1,
        pageSize: 10,
        total: 10,
        dataSource: [],
        checkList: [],
        hasEffective: hasEffective,
        isLoading: false
      }
    },
    created() {
      this.loadCasesData()
    },
    methods: {
      loadData() {
        switch (this.currentType) {
        case 'course':
          this.loadCasesData()
          break
        case 'special':
          this.loadCasesCollectData()
          break
        }
      // this.loadCasesData()
      // this.loadCasesCollectData()
      },
      async loadCasesData() {
        this.isLoading = true
        let params = {
          queryPage: this.currentPage,
          querySize: this.pageSize
        }
        let res = await getAccountCourse(params)
        if (!res.success) {
          this.$message.error(res.message)
          return
        }
        this.dataSource = res.list.map((item) => {
          return {
            id: item.id,
            courseId: item.courseId,
            coverUrl: item.coverUrl,
            name: item.name,
            isEffective: item.isEffective,
            invalidityCause: item.invalidityCause,
            collectionTime: item.collectionTime

          }
        })
        this.total = res.totalSize
        this.isLoading = false
      },
      async loadCasesCollectData() {
        this.isLoading = true
        let params = {
          queryPage: this.currentPage,
          querySize: this.pageSize
        }
        let res = await getAccountCollectCourse(params)
        if (!res.success) {
          this.$message.error(res.message)
          return
        }
        this.dataSource = res.list.map((item) => {
          return {
            id: item.id,
            courseId: item.collectionTopicsId,
            coverUrl: item.coverPictureUrl,
            name: item.title,
            profile: item.profile,
            isEffective: item.isEffective,
            invalidityCause: item.invalidityCause,
            collectionTime: item.collectionTime

          }
        })
        this.total = res.totalSize
        this.isLoading = false
      },
      handleCurrentChange(page) {
        this.currentPage = page
        switch (this.currentType) {
        case 'course':
          this.loadCasesData()
          break
        case 'special':
          this.loadCasesCollectData()
          break
        }
      },
      resetPageSize() {
        this.pageSize = 10
        this.currentPage = 1
      },
      alertCasesMsg(item) {
        this.$message({
          message: `抱歉,该课程${getEffectiveTypeStatus(item.invalidityCause)},请选择其他课程观看`,
          type: 'warning'
        })
      },
      alertSpecialMsg(item) {
        this.$message({
          message: `抱歉,该专题${getEffectiveTypeStatus(item.invalidityCause)},请选择其他专题观看`,
          type: 'warning'
        })
      },
      routerToCaseDetail(params) {
        let id = params.courseId || -1
        const routeUrl = this.$router.resolve({
          path: '/audit/caseDetails',
          query: {
            id: id
          }
        })
        window.open(routeUrl.href, '_blank')
      },
      routerToSpecialDetail(params) {
        let id = params.courseId || -1
        const routeUrl = this.$router.resolve({
          path: '/audit/specialDetail',
          query: {
            id: id
          }
        })
        window.open(routeUrl.href, '_blank')
      },
      isBatchOperation() {
        this.isCheck = !this.isCheck
        this.checkList = []
      },
      onClearSelected() {
        this.checkList = []
      },
      handleSelect(key) {
        this.currentType = key
        this.onClearSelected()
        this.resetPageSize()
        if (key === 'special') {
          this.loadCasesCollectData()
        }
        if (key === 'course') {
          this.loadCasesData()
        }
      },
      handleDelete() {
        if (this.checkList.length <= 0) {
          this.$message.warning('请选择一条记录！')
        } else {
          var ids = this.checkList.join(',')
          this.loading = true

          let act = () => {
            return new Promise()
          }
          switch (this.currentType) {
          case 'course':
            act = deleteCollectCourceById
            break
          case 'special':
            act = deleteCollectSpecialById
            break
          }

          act(ids).then(res => {
            if (res.success) {
              this.$message.success(res.message)
              this.loadData()
              this.onClearSelected()
            } else {
              this.$message.warning(res.message)
              this.loading = false
            }
          })
        }
      }

    }
  }
</script>

<style lang="less" scoped>
@import '~@/views/account/less/accountCourse.less';

.course-row {
  width: 100%;
  padding-left: 10px;
  display: flex;
  align-items: center;
}

.span {
  width: auto;
  height: 30px;
  cursor: pointer;
  line-height: 28px;
  padding: 0 10px;
  text-align: right;
  border: 1px solid #ccd0d7;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color .2s ease;
  box-sizing: border-box;
  margin-right: 25px;
}

.span:hover {
  cursor: pointer;
  color: @primary-color;
}

.back-to-info {
  width: 60px;
  height: 30px;
  cursor: pointer;
  line-height: 28px;
  padding-right: 10px;
  text-align: right;
  border: 1px solid #ccd0d7;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color .2s ease;
  box-sizing: border-box;
  margin-right: 20px;
}

.back-to-info:hover {
  cursor: pointer;
  color: @primary-color;
}

/deep/ .el-checkbox__label {
  flex: 1;
}

/deep/ .el-checkbox {
  color: #999;
}

/deep/ .el-menu.el-menu--horizontal {
  border: none;
}
</style>
