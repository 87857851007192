<template>
  <tr>
    <td v-if="item" class="text">
      <p-image
        :src="item.isEffective === hasEffective.VALID? item.coverUrl:'@/assets/img-error.png'"
        :alt="item.name"
        :style="{width:'144px',height:'95px'}"/>
      <div class="content-box">
        <div class="title">
          <span>{{ item.name }}</span>
          <span v-if="item.invalidityCause === effectiveType.OFF_SHELF" style="margin-left: 10px">【已下架】</span>
          <span v-if="item.invalidityCause === effectiveType.DELETED" style="margin-left: 10px">【已删除】</span>
        </div>
        <div v-if="item.profile" class="profile">
          {{
            item.profile
          }}
        </div>
        <div>收藏时间：{{ item.collectionTime }}</div>
      </div>
    </td>
    <td class="td-do" v-if="item.isEffective === hasEffective.VALID">
      <span
        v-if="item.profile"
        @click.stop="handleDeleteSpecial(item)"
        class="cancel"
        slot="reference">
          取消收藏
        </span>
      <span
        v-else
        @click.stop="handleDeleteCourse(item)"
        class="cancel"
        slot="reference">
          取消收藏
        </span>
    </td>
  </tr>
</template>

<script>
  import PImage from '@/components/project/image/PImage'
  import { effectiveType, hasEffective } from '@/enum/effectiveType'
  import { postCollectCourse } from '@/api/course'
  import { postCollectSpecial } from '@/api/special'

  export default {
    name: 'AccountCourseItem',
    components: { PImage },
    props: {
      item: {
        type: Object,
        default: () => {
        }
      }
    },
    data() {
      return {
        hasEffective: hasEffective,
        effectiveType: effectiveType

      }
    },
    methods: {
      initData() {
      },
      handleDeleteSpecial(item) {
        return new Promise((resolve, reject) => {
          postCollectSpecial({
            collectType: '0',
            id: item.courseId
          }).then(res => {
            resolve(res)
            this.$emit('specialDet')
          }).catch(res => {
            reject(res)
          }).finally(() => {
          })
        })
      },
      handleDeleteCourse(item) {
        return new Promise((resolve, reject) => {
          postCollectCourse({
            collectType: '0',
            id: item.courseId
          }).then(res => {
            resolve(res)
            this.$emit('courseDet')
          }).catch(res => {
            reject(res)
          }).finally(() => {

          })
        })
      },
      // 这是一个假方法
      stopPop() {
      // alert('阻止冒泡~~~')
      }
    }
  }
</script>

<style lang="less" scoped>
tr {
  display: flex;
}

td {
  vertical-align: middle;
  //padding: 10px;
  margin: 10px;
  text-align: left;
  border-bottom: 1px solid #f8f8f8;

  &.text {
    flex: 1;
  }

  &.text:hover {
    cursor: pointer;
  }

  img {
    float: left;
  }

  div {
    margin-left: 20px;
    float: left;

    .title {
      font-size: 16px;
    }
  }

  &.name {
    text-align: left;
  }
}

.content-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 95px;
  width: 600px;

  .profile {
    text-indent: 2em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 550px;
  }
}

.td-do {
  position: relative;
  width: 100px;

  .cancel {
    position: absolute;
    bottom: 10px;
    margin: 0 10px;
    cursor: pointer;
  }
}


a.gray_btn {
  width: 100px;
  height: 40px;
  padding: 3px 8px;
  border: 1px solid #ddd;
  background-color: @primary-color;
  border-radius: 3px;
  position: relative;
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  color: #fff;


  &.width30 {
    width: 30px;
  }

  &.width45 {
    width: 45px;
  }

  &.width50 {
    width: 50px;
  }

  &:hover {
    background: #66b1ff;
  }

}

</style>
