<template>
  <div class="person banner">
    <div class="person_body">
      <header class="side_box">
        <div class="menu_panel">
          <h3 class="title"><i class="iconfont">&#xe60a;</i>个人中心</h3>
          <ul class="menus">
            <li :class="{on: type == 'wdkc'}">
              <router-link :to="{name: 'account-course'}">我的课程</router-link>
            </li>
            <li :class="{on: type == 'wdsc'}">
              <router-link :to="{name: 'account-collect'}">我的收藏</router-link>
            </li>
<!--            <li :class="{on: type == 'grxx'}">-->
<!--              <router-link :to="{name: 'account-base'}">个人信息</router-link>-->
<!--            </li>-->
          </ul>
        </div>
      </header>
      <div class="main_box">
        <ul class="tabs">
          <a class="tab on">{{ tabText }}</a>
        </ul>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Account',
    components: {},
    props: {
      type: {
        type: String,
        default: 'wdkc'
      },
      tabText: {
        type: String,
        default: ''
      }
    },
    methods: {
      initData() {
      },
      loadData() {
      }
    }
  }
</script>

<style lang="less" scoped>
  .person {
    min-height: calc(100vh - 350px);
  }

  .person_body {
    width: 100%;
    display: flex;
    margin: 20px 0;

    .side_box {
      border-radius: 6px 6px 0 0;
      width: 168px;
      margin-right: 20px;

      .menu_panel {
        background-color: @background-color;
        margin-bottom: 20px;
        border-radius: 6px 6px 0 0;

        .act_icon {
          width: 16px;
          margin-right: 2px;
        }

        .title {
          font-size: 16px;
          font-weight: bold;
          color: #333;
          line-height: 60px;
          padding: 0 35px;
          border-bottom: 1px solid rgb(242, 242, 242);

          i {
            padding-right: 6px;
            font-size: 16px;
            position: relative;
            left: -5px;
          }
        }
      }

      .menus {
        li {
          line-height: 40px;
          // padding-left: 35px;
          text-align: center;

          &:hover, &.on {
            a {
              color: @primary-color;
            }
          }
        }

        a {
          color: #333;
          font-size: 14px;
        }
      }
    }

    .main_box {
      flex: 1;
      min-height: 520px;
      color: #999;
      background-color: @background-color;
      border-radius: 8px;
      position: relative;

      .tabs {
        border-bottom: 1px solid #f5f5f5;
        height: 48px;

        .tab {
          display: inline-block;
          text-align: center;
          line-height: 46px;
          width: 136px;
          cursor: pointer;
        }

        .on {
          color: @primary-color;
          border-bottom: 2px solid @primary-color;
        }

        .solid_btn {
          margin: 5px 10px;
        }
      }
    }
  }


</style>
