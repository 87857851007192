import { request } from '@/utils/axios'

// 获取用户课程收藏记录
export const getAccountCourse = (params) => {
  return new Promise((resolve, reject) => {
    request({
      url: '/api/v1/personalcenter/userCourseCollectionRecords/detail',
      method: 'get',
      params: params,
      verifyToken: true
    }).then(res => {
      if (res.success && res.list) {
        res.result = res.list
      }
      resolve(res)
    })
  })
}

// 获取用户专题收藏记录
export const getAccountCollectCourse = (params) => {
  return new Promise((resolve, reject) => {
    request({
      url: '/api/v1/personalcenter/userCollectionTopicsRecords/detail',
      method: 'get',
      params: params,
      verifyToken: true
    }).then(res => {
      if (res.success && res.list) {
        res.result = res.list
      }
      resolve(res)
    })
  })
}
